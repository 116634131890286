import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import Nativ from "../components/ClientPages/Nativ";

export default class NativPage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Nativ" />
        <Nativ />
      </Fragment>
    );
  }
}
