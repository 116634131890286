import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/nativ/video.mp4";
import Feature from "../../img/client_pages/nativ/feature.png";
import Design from "../../img/client_pages/nativ/design.png";
import Iphone from "../../img/client_pages/nativ/iphone.png";

export default class Nativ extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.NativMainImg}
        video={Video}
        title="Nativ Beauty"
        theBrand={
          "Nativ Beauty came to us as a start-up that wished to further define their vision and attract their tribe. We worked with the owner’s inspiration to create an identity that appealed to urban beauties across the country."
        }
        ourWork={[
          "Brand Identity",
          "Web Design",
          "Social Style",
          "Social Strategy"
        ]}
        brandDesign={
          "In order for Nativ Beauty to stand out as premium, approachable, and connected, we took a deep dive into their “why.” Nativ Beauty exists to help women feel beautiful inside and out, especially within an industry that often diminishes their confidence. By deepening into Nativ Beauty’s purpose and performing target market analysis, we created a take-no-prisoners strategy to help this brand reach and empower women."
        }
        featureImg={Feature}
        webDesignText={
          "We carefully curated Nativ Beauty’s website to marry form and function. Our goal for the site was to speak clearly to the company’s purpose, vision, and values while providing an easy and fun experience for shoppers."
        }
        webDesignImg={Design}
        socialDesignText={
          "We set out to create a consistent feed that would work within Nativ Beauty’s new brand standards and convey a premium atmosphere. Our vision was to ensure that Nativ Beauty's own content posts would go seamlessly with the feed."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
